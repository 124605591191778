































































































import Vue from 'vue'
import { Location } from 'vue-router'
import {
  AtriumLayout,
  HeroCard,
  BoxContent,
  ColorWidget,
  SponsorGrid,
  FeaturedSessions,
  SponsorGroup,
  deepSeal,
  Routes,
  mapApiState,
  mapMetricsState,
  ApiContent,
  getFeaturedSessions,
  SessionAndSlot,
} from '@openlab/deconf-ui-toolkit'
import AppLayout from '@/components/PdcAppLayout.vue'
import { PdcConferenceConfig } from '@/lib/module'

import sponsorData from '@/data/sponsors.json'

interface Data {
  sponsors: SponsorGroup[]
  reelObserver?: ResizeObserver
}

export default Vue.extend({
  components: {
    ApiContent,
    AppLayout,
    AtriumLayout,
    HeroCard,
    BoxContent,
    ColorWidget,
    SponsorGrid,
    FeaturedSessions,
  },
  data(): Data {
    return {
      sponsors: deepSeal(sponsorData),
      reelObserver: undefined,
    }
  },
  computed: {
    ...mapApiState('api', ['schedule', 'user']),
    ...mapMetricsState('metrics', ['siteVisitors']),
    settings(): PdcConferenceConfig | null {
      return (this.schedule?.settings as any) ?? null
    },
    siteVisitorsTitle(): string {
      return this.siteVisitors?.toString() ?? '~'
    },
    contentSlug(): string {
      return this.user ? 'home-personal' : 'home-public'
    },
    loginRoute(): Location {
      return { name: Routes.Login }
    },
    registerRoute(): Location {
      return { name: Routes.Register }
    },
    scheduleDate(): Date {
      return this.$dev.scheduleDate ?? this.$temporal.date
    },

    featuredSessions(): null | SessionAndSlot[] {
      return (
        getFeaturedSessions(
          this.schedule,
          7,
          this.scheduleDate,
          (s) => Boolean(s.slot) && s.isFeatured
        )?.slice(0, 3) ?? null
      )
    },
    widgets(): Set<string> {
      return new Set(
        Object.entries(this.settings?.widgets ?? {})
          .filter((entry) => entry[1] === true)
          .map((entry) => entry[0])
      )
    },
  },
  updated() {
    if (
      !this.reelObserver &&
      this.$refs.visualSchedule &&
      'ResizeObserver' in window
    ) {
      this.reelObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          entry.target.classList.toggle(
            'overflowing',
            entry.target.scrollWidth > entry.target.clientWidth
          )
        }
      })
      this.reelObserver.observe(this.$refs.visualSchedule as HTMLElement)
    }
  },
  destroyed() {
    this.reelObserver?.disconnect()
    this.reelObserver = undefined
  },
  methods: {
    plop(elem: ResizeObserverEntry) {
      console.log(elem)
    },
  },
})
