









import Vue from 'vue'
import UtilLayout from '@/components/PdcUtilLayout.vue'

export default Vue.extend({
  components: { UtilLayout },
})
